import {IHeadData} from "../../interface/head.data";

export interface HeadState {
 isLoaded: boolean
 isLoading: boolean
 isAvailable?: boolean
 error: any

 head?: IHeadData
}

export const HEAD_AVAILABLE = 'head/available';
interface HeadAvailableAction {
 type: typeof HEAD_AVAILABLE
}

export const HEAD_UNAVAILABLE = 'head/unavailable';
interface HeadUnavailableAction {
 type: typeof HEAD_UNAVAILABLE
}

export const HEAD_OK = 'head/ok';
interface HeadOkAction {
 type: typeof HEAD_OK
 head: IHeadData
}

export const HEAD_LOADED = 'head/loaded';
interface HeadLoadedAction {
 type: typeof HEAD_LOADED
 error: any
}

export const HEAD_ERROR = 'head/error';
interface HeadErrorAction {
 type: typeof HEAD_ERROR
 error: any
}

export type HeadActionTypes = HeadAvailableAction | HeadUnavailableAction | HeadLoadedAction | HeadOkAction | HeadErrorAction
