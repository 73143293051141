import {STRUCT_ERROR, STRUCT_FETCH, STRUCT_IMGS, STRUCT_OK, StructActionTypes, StructState} from "./types";

const iniState: StructState = {
 isLoaded: false,
 isLoading: false,
 error: undefined,
 pages: {},
 menu: [],
 imgs: {},
 common: {
  name: "Braintree",
  menuText: "",
  menuLinks: [],
  qrImg: "",
  vcfLink: "",
  vcfText: "",
  address: "",
  email: "",
  phone: ""
 },
 pageRoutes: [],
 popupRoutes: [],
}

export function structReducer(state = iniState, action: StructActionTypes): StructState {
 switch (action.type) {
  case STRUCT_FETCH:
   return {...state, isLoading: true, error: undefined};
  case STRUCT_OK:
   return {
    ...state,
    isLoading: false,
    isLoaded: true,
    pages: action.pages,
    menu: action.menu,
    common: action.common,
    imgs: action.imgs,
    pageRoutes: action.pageRoutes,
    popupRoutes: action.popupRoutes
   };
  case STRUCT_IMGS:
   return {...state, imgs: action.imgs};
  case STRUCT_ERROR:
   return {...state, isLoading: false, error: action.error};
  default:
   return state;
 }
}
