export const VAR_MIN_FRAMES = 5;
export const VAR_MIN_ANGLE = -180;
export const VAR_MIN_DISTANCE = 100;
export const VAR_MIN_HEIGHT = -250;
export const VAR_MIN_LIGHT = 0;

interface IKeyMinValues {
 [key: string]: number;
}

export const RulesMinValues: IKeyMinValues = {
 angle: VAR_MIN_ANGLE,
 distance: VAR_MIN_DISTANCE,
 height: VAR_MIN_HEIGHT,
 nod: VAR_MIN_ANGLE,
 tilt: VAR_MIN_ANGLE,
 intensity: VAR_MIN_LIGHT,
 ambient: VAR_MIN_LIGHT
};
