import {IStructCommonBlock, IStructImgs, IStructMenuItem, IStructPages, IStructRoute} from "../../interface/struct";

export interface StructState {
 isLoaded: boolean
 isLoading: boolean
 error: any

 pages: IStructPages
 menu: IStructMenuItem[]
 common: IStructCommonBlock
 imgs: IStructImgs
 pageRoutes: IStructRoute[]
 popupRoutes: IStructRoute[]
}

export const STRUCT_FETCH = 'struct/fetch';
interface StructFetchAction {
 type: typeof STRUCT_FETCH
}

export const STRUCT_OK = 'struct/ok';
interface StructOkAction {
 type: typeof STRUCT_OK
 pages: IStructPages
 menu: IStructMenuItem[]
 common: IStructCommonBlock
 imgs: IStructImgs
 pageRoutes: IStructRoute[]
 popupRoutes: IStructRoute[]
}

export const STRUCT_IMGS = 'struct/imgs';
interface StructImgsAction {
 type: typeof STRUCT_IMGS
 imgs: IStructImgs
}

export const STRUCT_ERROR = 'struct/error';
interface StructErrorAction {
 type: typeof STRUCT_ERROR
 error: any
}

export type StructActionTypes = StructFetchAction | StructOkAction | StructImgsAction | StructErrorAction
