export interface IRandomInterval {
 min: number
 max: number
}

export const randomValue = (i: IRandomInterval, int = false): number => {
 const rnd = (i.max === i.min) ? i.min : Math.random() * (i.max - i.min) + i.min;
 return int ? Math.floor(rnd) : rnd;
}

export const JsonParseRandomInterval = (json: number[], min = 0, max = 0): IRandomInterval => {
 return (json.length > 1) ? {min: json[0], max: json[1]} : {min: json[0] || min, max: json[0] || max}
}

export const StringParseRandomInterval = (str: string, min = 0): IRandomInterval => {
 const s: string = str || "";
 const nums: number[] = s.split(",").map(f => {
  const num = parseFloat(f);
  return (isNaN(num) || num < min) ? min : num;
 });

 if(nums.length === 1) {
  return {min: nums[0], max: nums[0]};
 }
 return {min: Math.min(nums[0], nums[1]), max: Math.max(nums[0], nums[1])};
}

export const StringParseDots = (str: string): number[] => {
 return (str) ? str.split(",").map(f => parseFloat(f)).filter(f => !isNaN(f)) : [];
}
