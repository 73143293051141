import React, {Suspense, lazy} from "react";

const AppLoadedProvider = lazy(() => import("./AppProvider"));

const AppLoadedFallback: React.FC = () => null

const AppLoadedComponent: React.FC = () => {
 return (
  <Suspense fallback={<AppLoadedFallback/>}>
   <AppLoadedProvider/>
  </Suspense>
 )
}

const AppLoaded: React.FC = () => {
 return <AppLoadedComponent/>
}

export default AppLoaded;