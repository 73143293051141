import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/App";
import './scss/index.scss';
import configureStore from "./store";
import {Provider} from "react-redux";

const packageJson = require('../package.json');
if(packageJson && window.console)
 console.log("Version", packageJson.version);

const store = configureStore();
const Root = () => <Provider store={store}><App/></Provider>
ReactDOM.render(<Root />, document.getElementById('root'));
