import React, {Suspense, lazy} from "react";

const HeadBox = lazy(() => import("./HeadBox"));

const HeadLoaderFallback: React.FC = () => {
 return null;
}

const HeadLoaderComponent: React.FC = () => {
 return (
  <Suspense fallback={<HeadLoaderFallback />}>
   <HeadBox />
  </Suspense>
 )
}

export const HeadLoader: React.FC = () => {
 return <HeadLoaderComponent />;
}