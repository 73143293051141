import {HEAD_AVAILABLE, HEAD_ERROR, HEAD_LOADED, HEAD_OK, HEAD_UNAVAILABLE, HeadActionTypes, HeadState} from "./types";

const iniState: HeadState = {
 isLoaded: false,
 isLoading: false,
 isAvailable: undefined,
 error: undefined,
 head: undefined,
}

export function headReducer(state = iniState, action: HeadActionTypes): HeadState {
 switch (action.type) {
  case HEAD_AVAILABLE:
   return {...state, isAvailable: true, isLoading: true};
  case HEAD_UNAVAILABLE:
   return {...state, isAvailable: false};
  case HEAD_OK:
   return {...state, isLoading: false, head: action.head};
  case HEAD_LOADED:
   return {...state, isLoaded: true, error: action.error};
  case HEAD_ERROR:
   return {...state, isLoading: false, isAvailable: false, error: action.error};
  default:
   return state;
 }
}