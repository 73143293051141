import React from "react";
import head from "../assets/svg/head.svg";
import AppLoaded from "./AppLoaded";
import {HeadLoader} from "./HeadLoader";
import {AppState} from "../store";
import {useSelector} from "react-redux";

const AppLoadingBox: React.FC = () => {
 return (
  <div className={"AppLoadingBox"}>
   <div className={"box"}>
    <div className={"loader-box"}>
     <div className={"SpinLoader"}>Loading...</div>
     <div className={"info"}>braintree / <span className="status">loading…</span></div>
    </div>
    <img className={"head"} src={head} alt={""}/>
   </div>
  </div>
 )
}

interface IRedux {
 isStructLoaded: boolean
 isHeadAvailable?: boolean
 isHeadLoaded: boolean
}

export const AppLoader: React.FC = () => {
 const {isStructLoaded, isHeadAvailable, isHeadLoaded} = useSelector<AppState, IRedux>(state => ({
  isStructLoaded: state.structState.isLoaded,
  isHeadAvailable: state.headState.isAvailable,
  isHeadLoaded: state.headState.isLoaded,
 }))

 const isAppLoaded = Boolean(isStructLoaded && ((isHeadAvailable===true && isHeadLoaded) || (isHeadAvailable===false)));

 return (
  <div className={"AppLoader" + (isAppLoaded ? " AppLoaded" : "")}>
   {isHeadAvailable && <HeadLoader/>}
   {isAppLoaded && <AppLoaded/>}
   <AppLoadingBox/>
  </div>
 )
}
