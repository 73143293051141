import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {structReducer} from "./struct/reducers";
import {headReducer} from "./head/reducers";

const rootReducer = combineReducers({
 structState: structReducer,
 headState: headReducer,
});

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
 const middlewares = [thunk];
 const middlewareEnhancer = applyMiddleware(...middlewares);

 const store = createStore(
  rootReducer,
  composeWithDevTools(middlewareEnhancer)
 );

 return store;
}