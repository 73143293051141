import {IHeadDataRouter, IHeadRouteMap} from "./head.route";
import {IHeadSetMap, IHeadSetMapJson, JsonParseHeadSet} from "./head.set";

export interface IHeadDataModel {
 path: string
 obj: string
 mtl: string
}

interface IHeadDataCommon {
 model: IHeadDataModel
 router: IHeadDataRouter
 routes: IHeadRouteMap
}

export interface IHeadData extends IHeadDataCommon {
 sets: IHeadSetMap
}

export interface IHeadDataJson extends IHeadDataCommon {
 sets: IHeadSetMapJson
}

export const JsonParseHeadData = (json: IHeadDataJson): IHeadData => {
 const sets: IHeadSetMap = {};
 for(const setId in json.sets) {
  try {
   sets[setId] = JsonParseHeadSet(json.sets[setId]);
  }
  catch (err) {
   console.error('Error parsing set #' + setId, err);
  }
 }

 const routes: IHeadRouteMap = {};
 for(const routeId in json.routes) {
  const route = json.routes[routeId];
  const routeSets: string[] = route.sets? route.sets.filter(f => Boolean(sets[f])): [];
  routes[routeId] = {...route, sets: routeSets};
 }

 return {...json, routes, sets};
}