export const bezier = (t: number, p: number[]): number => {
 const l = p.length;
 if(l < 2)
  return p[0];

 let res = p[0] * Math.pow(1 - t, l - 1);
 for (let i = 1; i < l - 1; i++)
  res += (l - 1) * p[i] * Math.pow(t, i) * Math.pow(1 - t, l - 1 - i);
 res += p[l - 1] * Math.pow(t, l - 1);
 return res;
}
