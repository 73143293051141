import React from 'react';
import {HashRouter as Router} from "react-router-dom";
import {connect} from "react-redux";
import {AppState} from "../store";
import {winVhFix} from "../utils/win";
import {structLoad} from "../store/struct/actions";
import {AppLoader} from "./AppLoader";
import {headIni} from "../store/head/actions";

interface IProps {
 structError: any
 structLoad: any
 headIni: any
}

const mapState = (state: AppState) => ({
 structError: state.structState.error,
});

const mapDispatch = {
 structLoad,
 headIni
}

const App: React.FC<IProps> = ({structError, structLoad, headIni}) => {
 React.useEffect(() => {
  structLoad();
  headIni();
  winVhFix();
  window.addEventListener("resize", winVhFix);
  return () => {
   window.removeEventListener("resize", winVhFix);
  }
 }, [structLoad, headIni]);

 return (
  <Router>
   <div className={"App"}>
    {Boolean(structError)? <div className="AppError">{structError.toString()}</div>: <AppLoader/>}
   </div>
  </Router>
 );
}

export default connect(mapState, mapDispatch)(App);
