

export const http = <T>(request: RequestInfo): Promise<T> => {
 return fetch(request)
  .then(response => {
   const ok = response.ok;
   return response.json()
    .then(json => {
     if (!ok) {
      throw json;
     }
     return json as T;
    })
  })
};
