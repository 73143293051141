import {ThunkAction} from "redux-thunk";
import {HEAD_AVAILABLE, HEAD_ERROR, HEAD_LOADED, HEAD_OK, HEAD_UNAVAILABLE, HeadActionTypes, HeadState} from "./types";
import {IHeadDataJson, JsonParseHeadData} from "../../interface/head.data";
import {http} from "../../utils/http";
import {DevVar} from "../../utils/dev";

type HeadThunkAction = ThunkAction<void, HeadState, undefined, HeadActionTypes>;

export const headIni = (): HeadThunkAction => async dispatch => {
 try {
  const width = window.innerWidth || window.document.documentElement.clientWidth || window.document.body.clientWidth;
  const height = window.innerHeight || window.document.documentElement.clientHeight || window.document.body.clientHeight;
  const isDesktop = Boolean((width > 640) && (height > 620));
  const devNoHead: boolean = DevVar('no-head');
  if (isDesktop && !devNoHead) {
   dispatch({type: HEAD_AVAILABLE});
   const headJson = "/data/head.json?nocache=" + (new Date().getTime());
   const req = new Request(headJson, {method: "GET"});
   const res: IHeadDataJson = await http<IHeadDataJson>(req);
   const head = JsonParseHeadData(res);
   dispatch({type: HEAD_OK, head});
  }
  else {
   dispatch({type: HEAD_UNAVAILABLE});
  }
 }
 catch (error) {
  console.error('head init error', error);
  dispatch({type: HEAD_ERROR, error});
 }
}

export const headLoaded = (error: any): HeadActionTypes => {
 if(!error) {
  const rootEl = window.document.getElementById("root");
  if (rootEl)
   rootEl.classList.add("root-head-loaded");
 }
 return {type: HEAD_LOADED, error};
}
