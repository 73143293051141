import {IRandomInterval, randomValue, StringParseDots, StringParseRandomInterval} from "./head.common";
import {HEAD_FUNC_ABS, HEAD_FUNC_REL, HeadFunc} from "./head.functions";
import {bezier} from "./bezier";
import {VAR_MIN_ANGLE, VAR_MIN_DISTANCE, VAR_MIN_FRAMES, VAR_MIN_HEIGHT, VAR_MIN_LIGHT} from "../utils/vars";

export class HeadSetRule {
 protected value: IRandomInterval;
 protected func: HeadFunc;
 protected params: number[];
 protected dots: number[];
 protected v: number;

 public constructor(value: IRandomInterval, func: HeadFunc, params: number[]) {
  this.value = value;
  this.func = func;
  this.params = params;
 }

 public getFunc(): HeadFunc {
  return this.func;
 }

 public getParams(): number[] {
  return this.params;
 }

 public generate(orig: number, base: number): number {
  switch (this.func) {
   case HEAD_FUNC_ABS:
    return this.generateAbs(orig);
   case HEAD_FUNC_REL:
    return this.generateRel(orig, base);
  }
  this.v = orig;
  this.dots = [];
  return orig;
 }

 protected generateAbs(orig: number): number {
  this.v = randomValue(this.value);
  this.dots = this.params.reduce((prv, cur) => [...prv, orig + cur / 100 * (this.v - orig)], [orig]);
  return this.dots.length > 1 ? bezier(1, this.dots) : this.v;
 }

 protected generateRel(orig: number, base: number): number {
  this.v = base + randomValue(this.value);
  this.dots = this.params.reduce((prv, cur) => [...prv, orig + cur / 100 * (this.v - orig)], [orig]);
  return this.dots.length > 1 ? bezier(1, this.dots) : this.v;
 }

 public generateAng(orig: number, base: number, angleK: number, angleParams: number[]): number {
  const r = randomValue(this.value);
  this.v = r + angleK * (base - r);
  this.dots = angleParams.reduce((prv, cur) => [...prv, orig + cur / 100 * (this.v - orig)], [orig]);
  return this.dots.length > 1 ? bezier(1, this.dots) : this.v;
 }

 public generageByAngle(orig: number, base: number, baseAngle: number, angleSet: HeadSetRule): number {
  const p = (this.params && this.params.length > 0) ? this.params[0] : 0;
  const angle = angleSet.step(1);
  const k = (p - baseAngle === 0) ? 1 : (p - angle) / (p - baseAngle);
  const r = randomValue(this.value);
  this.v = r + k * (base - r);
  this.dots = angleSet.getParams().reduce((prv, cur) => [...prv, orig + cur / 100 * (this.v - orig)], [orig]);
  return this.dots.length > 1 ? bezier(1, this.dots) : this.v;
 }

 public step(t: number): number {
  return (this.dots && this.dots.length > 1) ? bezier(t, this.dots) : this.v;
 }
}

export interface IHeadSetRuleFunc {
 id: string
 params: string[]
}

export interface IHeadSetRule {
 value: IRandomInterval
 functions: IHeadSetRuleFunc[]
 dots: number[]
}

export const JsonParseHeadSetRule1 = (str: string, minValue = 0): HeadSetRule => {
 const parts: string[] = str.split(";");
 const value = StringParseRandomInterval(parts[0], minValue);
 if (!parts[1])
  throw new Error(`No func for rule [${str}]`);
 const funcParts = parts[1].split(":");
 const func: HeadFunc = funcParts[0] as HeadFunc;
 const params: number[] = funcParts[1] ? funcParts[1].split(",").map(f => parseFloat(f)).filter(f => !isNaN(f)) : [];

 return new HeadSetRule(value, func, params);
}

export const JsonParseHeadSetRule = (str: string, minValue = 0): IHeadSetRule => {
 const parts: string[] = str? str.split(";"): [];
 const value = StringParseRandomInterval(parts[0], minValue);
 const dots: number[] = StringParseDots(parts[1] || "");
 const functions: IHeadSetRuleFunc[] = [];
 if (parts.length > 2) {
  for (let i = 2; i < parts.length; i++) {
   const fp = parts[i].split(":");
   if (fp[0])
    functions.push({id: fp[0], params: (fp[1]) ? fp[1].split(",") : []});
  }
 }
 return {value, dots, functions}
}

export interface IHeadSet {
 frames: IRandomInterval // кол-во фреймов
 angle?: IHeadSetRule
 distance?: IHeadSetRule
 height?: IHeadSetRule
 nod?: IHeadSetRule
 tilt?: IHeadSetRule
 intensity?: IHeadSetRule
 ambient?: IHeadSetRule
}

export interface IHeadSetJson {
 frames: string
 angle?: string
 distance?: string
 height?: string
 nod?: string
 tilt?: string
 intensity?: string
 ambient?: string
}

export const JsonParseHeadSet = (json: IHeadSetJson): IHeadSet => {
 const set: IHeadSet = {
  frames: StringParseRandomInterval(json.frames, VAR_MIN_FRAMES)
 };
 if (json.angle)
  set.angle = JsonParseHeadSetRule(json.angle, VAR_MIN_ANGLE);
 if (json.distance)
  set.distance = JsonParseHeadSetRule(json.distance, VAR_MIN_DISTANCE);
 if (json.height)
  set.height = JsonParseHeadSetRule(json.height, VAR_MIN_HEIGHT);
 if (json.nod)
  set.nod = JsonParseHeadSetRule(json.nod, VAR_MIN_ANGLE);
 if (json.tilt)
  set.tilt = JsonParseHeadSetRule(json.tilt, VAR_MIN_ANGLE);
 if (json.intensity)
  set.intensity = JsonParseHeadSetRule(json.intensity, VAR_MIN_LIGHT);
 if (json.ambient)
  set.ambient = JsonParseHeadSetRule(json.ambient, VAR_MIN_LIGHT);
 return set;
}

export interface IHeadSetMap {
 [key: string]: IHeadSet
}

export interface IHeadSetMapJson {
 [key: string]: IHeadSetJson
}