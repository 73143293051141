import {ThunkAction} from "redux-thunk";
import {STRUCT_ERROR, STRUCT_FETCH, STRUCT_IMGS, STRUCT_OK, StructActionTypes, StructState} from "./types";
import {http} from "../../utils/http";
import {
 IStructCommonBlock, IStructImgs, IStructJson, IStructMenuItem, IStructPage, IStructPageJson, IStructPages,
 IStructRoute
} from "../../interface/struct";
import {strFixUri} from "../../utils/str";

type StructThunkAction = ThunkAction<void, StructState, undefined, StructActionTypes>;

interface IRouteFromStruct {
 route: string
 isPopup: boolean
}

const routeFromStruct = (jsonRoute: string, jsonHandler: string, pid?: string): IRouteFromStruct => {
 const hl = jsonHandler.length;
 const isPopup = Boolean(pid && hl > 6 && jsonHandler.substr(hl - 6) === "-popup");
 const route = `/${jsonRoute.replace("/", "")}`;
 return {route, isPopup};
}

const structFromJson = (json: IStructPageJson[], pid?: string): IStructPage[] => {
 const res: IStructPage[] = [];
 json.forEach(item => {
  const {handler, name, bg, head, title, titleBack, titleOne, titleMany, img, description} = item;
  let {route, isPopup} = routeFromStruct(item.route, handler, pid);
  let children = (item.children && item.children.length > 0) ? item.children.map(f => routeFromStruct(f.route, f.handler, route).route) : undefined;
  res.push({route, name, handler, bg, head, title, titleBack, titleOne, titleMany, img, description, children, pid, isPopup});
  if (item.children && item.children.length > 0) {
   const subs = structFromJson(item.children, route);
   if (subs.length > 0) {
    subs.forEach(sub => res.push(sub));
   }
  }
 });
 return res;
}

const loadImg = (id: string, path: string): Promise<HTMLImageElement | null> => new Promise(resolve => {
 const img = new Image();
 img.onload = () => {
  resolve(img);
 };
 img.onerror = () => {
  resolve(null);
 };
 img.id = id;
 img.src = path;
});

export const structLoad = (): StructThunkAction => async dispatch => {
 try {
  dispatch({type: STRUCT_FETCH});
  const structJson = "/data/struct.json?nocache=" + (new Date().getTime());
  const req = new Request(structJson, {method: "GET"});
  const res: IStructJson = await http<IStructJson>(req);
  const imgsList = await Promise.all(Object.keys(res.images).map(id => loadImg(id, res.images[id])));
  const imgs: IStructImgs = imgsList.reduce((p, c) => {
   if (c)
    p[c.id] = c;
   return p;
  }, {} as IStructImgs);
  const structList: IStructPage[] = structFromJson(res.pages);
  const pageRoutes: IStructRoute[] = [];
  const popupRoutes: IStructRoute[] = [];
  const pages: IStructPages = structList.reduce((p, c) => {
   const {route, handler, name, bg, head, title, titleBack, titleOne, titleMany, img, description, pid, children, isPopup} = c;
   if(!p[route]) {
    p[route] = {route, handler, name, bg, head, title, titleBack, titleOne, titleMany, img, description, pid, children, isPopup};
    if(isPopup && pid)
     popupRoutes.push({route, handler});
    else
     pageRoutes.push({route, handler});
   }
   return p;
  }, {} as IStructPages);
  const menu: IStructMenuItem[] = res.menu.map((m: IStructMenuItem) => ({path: strFixUri(m.path), title: m.title} as IStructMenuItem));
  const common: IStructCommonBlock = res.common;
  dispatch({type: STRUCT_OK, pages, menu, common, imgs, pageRoutes, popupRoutes});//, popupRoutes: Object.values(popupRoutesMap)});
 }
 catch (error) {
  console.error('struct init error', error);
  dispatch({type: STRUCT_ERROR, error});
 }
}

export const structImgs = (imgs: IStructImgs): StructActionTypes => ({type: STRUCT_IMGS, imgs});
